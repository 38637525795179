<div class="sky-card">
  <div class="sky-card-header">
    <h1 class="h2 mb-0">Nesting Workshop</h1>
  </div>
  <div class="sky-card-body">
    <app-wiki-link [wiki]="{href: 'https://sky-children-of-the-light.fandom.com/wiki/Nesting_Workshop'}"></app-wiki-link>
  </div>
</div>

<div class="mt">
  <app-card [foldable]="true" [folded]="false" [title]="'Nesting Challenges'">
    <div class="sky-flex scroll-x">
      @for (spirit of challengeSpirits; track spirit.guid) {
        <app-spirit-tree [tree]="spirit.tree!" [name]="spirit.name"></app-spirit-tree>
      }
    </div>
  </app-card>
</div>

<div class="mt">
  <app-card [foldable]="true" [folded]="false" [title]="'Workshop Props'">
    <div class="container mb">
      The Nesting Workshop rotates between available props every week.
      Please note that props from multiple rotations may be available at the same time! See <a href="https://github.com/Silverfeelin/SkyGame-Planner/issues/265" target="_blank">issue 265</a> for more details.<br/>
      <span class="c-accent">Please report any inconsistencies to Silverfeelin on GitHub or Skycord! TGC has the tendency to change these rotations.</span>
    </div>
    <div class="sky-flex flex-wrap flex-align-start scroll-x">
      <ng-container [ngTemplateOutlet]="tRotation" [ngTemplateOutletContext]="{ $implicit: permanentRotation, title: 'Permanent Items' }"></ng-container>
      @for (rotation of rotations; let i = $index; track i) {
        <ng-container [ngTemplateOutlet]="tRotation" [ngTemplateOutletContext]="{ $implicit: rotation, i: i, title: 'Rotation #' + (i + 1) }"></ng-container>
      }

      <ng-template #tRotation let-rotation let-title="title" let-i="i">
        <div class="shop-rotation">
          <div><b class="shop-rotation-header">{{ title }}</b></div>
          @let expectedDate = rotation[0]?.expectedDate;
          @if (expectedDate) {
            @if (i === iRotation) {
              <div>Current rotation</div>
            } @else {
              <div>Expected date: <app-date [date]="expectedDate"></app-date></div>
            }
          }
          <hr class="mt mb"/>
          <div class="shop-rotation-items">
            @for (r of rotation; track r.item; let j = $index) {
              <div class="shop-rotation-item">
                <div class="shop-rotation-icon">
                  <app-item-icon [item]="r.item!" [hoverGlow]="false" [subIcons]="['unlock']"></app-item-icon>
                </div>
                <div class="shop-rotation-name">
                  <span>{{ r.item!.name}}</span>
                </div>
                <div class="shop-rotation-amount">
                  <div class="button-group">
                    <button type="button" (click)="addQuantity(r.item!.guid, -1)">-1</button>
                    <button type="button" (click)="addQuantity(r.item!.guid, 1)">+1</button>
                  </div>
                  @let unlock = data.unlocked[r.item!.guid];
                  x {{ unlock?.q || 0 }}
                  @if (unlock?.lq) {
                    <span class="c-accent"> (+{{ unlock!.lq }})</span>
                  }
                  @if (unlock?.q) {
                    =
                    <app-cost [cost]="unlock!.cost"></app-cost>
                  }
                </div>
                <div class="shop-rotation-cost">
                  <app-cost [cost]="r"></app-cost>
                </div>
              </div>
            }
          </div>
        </div>
      </ng-template>
    </div>
  </app-card>
</div>

<div class="mt">
  <app-card [foldable]="true" [folded]="true" [lazy]="true" [title]="'Workshop Props (legacy)'">
    <div class="container mb">
      Before patch 0.27.0 (<app-date [date]="'2024-10-10'"></app-date>), the Nesting Workshop sold some items in a quantity.<br/>
      If you purchased items before this patch, please mark them as purchased here.
    </div>
    <div class="sky-flex flex-wrap">
      <div class="shop-rotation d-inline-block">
        <b class="d-block mb-half">
          Introduction
        </b>
        <app-item-list [itemList]="legacyFreeItemList" [highlightNode]="highlightNode" (beforeNodeToggle)="onBeforeLegacy($event)"></app-item-list>
      </div>
      @for (itemList of itemLists; let i = $index; track i) {
        <div class="shop-rotation d-inline-block">
          <b class="d-block mb-half">
            Rotation {{ i + 1 }}
          </b>
          <app-item-list class="d-inline-block" [itemList]="itemList" [highlightNode]="highlightNode" (beforeNodeToggle)="onBeforeLegacy($event)"></app-item-list>
        </div>
      }
      <div class="shop-rotation d-inline-block">
        <b class="d-block mb-half">
          Corrections
        </b>
        <app-item-list [itemList]="legacyCorrectionItemList" [highlightNode]="highlightNode" (beforeNodeToggle)="onBeforeLegacy($event)"></app-item-list>
      </div>
    </div>
  </app-card>
</div>
